import { FooterSection } from "../../components/footer.jsx";
import { useFetch } from "../../hooks/useFetch.jsx";
import { StyledLink } from "../../components/misc.jsx";
import { Table } from "../../components/table.jsx";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { baseColumnDef, extendBaseColumnDef } from "../../utils/table.jsx";

export function PlayerWithRenderer(params) {
  return (
    <StyledLink to={`/player/${params.data.player_id_played_with}`}>
      {params.value}
    </StyledLink>
  );
}

export function PlayedWithTab() {
  const { playerId } = useParams();
  const [visible, setVisible] = useState(false);

  function getColumnDef(key) {
    if (key == "player") {
      return extendBaseColumnDef(key, { hide: true });
    } else if (key == "season") {
      return extendBaseColumnDef(key, { sort: "desc" });
    } else if (key == "player_played_with") {
      return extendBaseColumnDef(key, {
        cellRenderer: PlayerWithRenderer,
      });
    } else if (key == "offense_for_per_60") {
      return extendBaseColumnDef(key, {
        headerTooltip:
          "offensive actions (missed shots, failed shot attempts, shots on goal or goals) for while the player pair was on the ice",
      });
    } else if (key == "offense_against_per_60") {
      return extendBaseColumnDef(key, {
        headerTooltip:
          "offensive actions (missed shots, failed shot attempts, shots on goal or goals) against while the player pair was on the ice",
      });
    } else {
      return baseColumnDef(key);
    }
  }

  function getRowData(data) {
    return data.filter((item) => item.player_id == playerId);
  }

  const tableInfo = useFetch(getColumnDef, getRowData, "played_with_stat");

  return (
    <>
      <Table setVisible={setVisible} tableInfo={tableInfo} visible={visible} />
      <FooterSection visible={visible} />
    </>
  );
}
