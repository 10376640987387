export function baseColumnDef(key) {
    return {
      field: key,
      filter: true,
      headerName: key.replace(/_/g, " "),
      hide: key.includes("player_id"),
      resizable: false,
      suppressMovable: true,
    };
  }
  
  export function extendBaseColumnDef(key, properties) {
    return { ...baseColumnDef(key), ...properties };
  }