import { Flexbox } from "./boxes.jsx";
import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Colors,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import Styled, { styled } from "styled-components";

ChartJS.register(
  Colors,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const ChartBox = Styled.div`
  height: 350px;
  margin: 20px;
  width: 33%;
  @media (max-width: 800px) {
    padding-bottom: 30px;
    width: 90%;
  }
`;

const SelectorBox = styled(Flexbox)`
  height: 25px;
  justify-content: start;
  margin-bottom: 30px;
  margin-left: 60px;
`;

const StyledSelect = styled(Select)`
  margin-left: 5px;
`;

function Selector({ options, selectedOptions, setSelectedOptions, toChange }) {
  return (
    <StyledSelect
      value={selectedOptions[toChange]}
      onChange={(e) =>
        setSelectedOptions({ ...selectedOptions, [toChange]: e.target.value })
      }
      sx={{ height: 25, fontSize: 14 }}
    >
      {options.map((option) => (
        <MenuItem value={option.value}>{option.label}</MenuItem>
      ))}
    </StyledSelect>
  );
}

export function BarChart({
  chartInfo,
  getValue,
  getYAxisTitle,
  quantity,
  suffix = null,
  quantityOptions = null,
  suffixOptions = null,
}) {
  const [selectedOptions, setSelectedOptions] = useState({
    quantity: quantity,
    suffix: suffix,
  });

  const seasons = chartInfo.rowData.map((item) => item.season);
  const data = {
    labels: seasons,
    datasets: [
      {
        label: chartInfo.player,
        data: chartInfo.rowData.map((item) =>
          getValue(
            item,
            selectedOptions.quantity,
            selectedOptions.suffix,
            chartInfo.playerType
          )
        ),
        borderWidth: 1,
        borderColor: "rgba(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  if (chartInfo.comparisonPlayer["player"]) {
    const comparisonSeasons = chartInfo.comparisonRowData.map(
      (item) => item.season
    );
    const comparisonValues = chartInfo.comparisonRowData.map((item) =>
      getValue(
        item,
        selectedOptions.quantity,
        selectedOptions.suffix,
        chartInfo.playerType
      )
    );
    const valueMap = new Map(
      comparisonSeasons.map((season, index) => [
        season,
        comparisonValues[index],
      ])
    );
    const comparisonData = seasons.map((season) =>
      valueMap.has(season) ? valueMap.get(season) : null
    );
    data["datasets"].push({
      label: chartInfo.comparisonPlayer["player"],
      data: comparisonData,
      borderWidth: 1,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    });
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: getYAxisTitle(selectedOptions.quantity, selectedOptions.suffix),
        },
      },
    },
  };

  return (
    <ChartBox>
      <SelectorBox>
        {quantityOptions && (
          <Selector
            options={quantityOptions}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            toChange={"quantity"}
          />
        )}
        {suffixOptions && (
          <Selector
            options={suffixOptions}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            toChange={"suffix"}
          />
        )}
      </SelectorBox>
      <Bar data={data} options={options} />
    </ChartBox>
  );
}
