import { About } from "./pages/about.jsx";
import { Home } from "./pages/home.jsx";
import { Player } from "./pages/player/player.jsx";
import React from "react";
import { createRoot } from "react-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";

const lightTheme = {
  colors: {
    hover: "#33596C",
    link: "#0174be",
    title: "#05314c",
  },
};

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Roboto", sans-serif;
    margin: 0;
   }

  @font-face {
    font-family: 'FranklinGothic';
    src: url('fonts/Franklin_Gothic_Heavy_Regular.ttf') format('truetype');
  }

   body { 
    padding-bottom: 25px;
   }
`;

function App() {
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyle />
        <HashRouter basename="/">
          <Routes>
            <Route exact path="/" element={<About />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/player/:playerId" element={<Player />}></Route>
          </Routes>
        </HashRouter>
      </ThemeProvider>
    </>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
