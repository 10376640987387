import { AgGridReact } from "ag-grid-react";
import { ColumnFlexbox, Flexbox } from "./boxes.jsx";
import { StyledLink, TeamLogo } from "./misc.jsx";
import React, { useCallback, useRef, useState } from "react";
import Styled, { styled } from "styled-components";

const QuickFilter = Styled.input`
  margin-bottom: 10px;
`;

const QuickFilterWrapper = Styled.div`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  width: ${(props) => props.width};
`;

const TableWrapper = Styled.div.attrs({ className: "ag-theme-quartz" })`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  width: ${(props) => props.width};
`;

export function PlayerRenderer(params) {
  return (
    <StyledLink to={`/player/${params.data.player_id}`}>
      {params.value}
    </StyledLink>
  );
}

const TeamLogoBox = styled(Flexbox)`
  height: 20px;
  margin-right: 5px;
  width: 20px;
`;

const TeamBox = Styled.div`
  align-items: center;
  display: flex;
`;

const getRowStyle = (params) => {
  if (params.data.season) {
    if (parseInt(params.data.season.split("-")[0]) % 2 == 0) {
      return { backgroundColor: "#E2E2E9" };
    }
  }
  return null;
};

export function TeamRenderer(params) {
  return (
    <TeamBox>
      {params.value != "none" && (
        <TeamLogoBox>
          <TeamLogo src={`/images/team_logos/${params.value}.svg`}></TeamLogo>
        </TeamLogoBox>
      )}
      <span>{params.value}</span>
    </TeamBox>
  );
}

export function Table({
  pagination = false,
  quickFilter = false,
  setVisible,
  tableInfo,
  visible,
}) {
  const [width, setWidth] = useState("100%");

  const gridRef = useRef();

  if (tableInfo.rowData) {
    if (tableInfo.rowData.length == 0) {
      setTimeout(() => {
        setVisible(true);
      }, 100);
    }
  }

  return (
    <ColumnFlexbox>
      {quickFilter && (
        <QuickFilterWrapper visible={visible} width={width}>
          <QuickFilter
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onInput={useCallback(() => {
              gridRef.current?.api.setGridOption(
                "quickFilterText",
                document.getElementById("filter-text-box").value
              );
            }, [])}
          />
        </QuickFilterWrapper>
      )}
      <Flexbox>
        <TableWrapper visible={visible} width={width}>
          <AgGridReact
            autoSizeStrategy={{ type: "fitCellContents" }}
            columnDefs={tableInfo.columnDefs}
            domLayout={"autoHeight"}
            getRowStyle={getRowStyle}
            pagination={pagination}
            paginationPageSize={20}
            onFirstDataRendered={() => {
              setTimeout(() => {
                setVisible(true);
              }, 100);
            }}
            onStateUpdated={() => {
              const currentColumnDefs = gridRef.current?.api.getColumnDefs();
              if (currentColumnDefs != undefined) {
                let gridWidth = 0;
                currentColumnDefs.forEach((item) => {
                  if (!item.hide) {
                    gridWidth += item.width;
                  }
                });
                gridWidth = Math.min(gridWidth, 0.95 * window.innerWidth);
                setWidth(`${gridWidth}px`);
              }
            }}
            tooltipShowDelay={500}
            ref={gridRef}
            rowData={tableInfo.rowData}
          />
        </TableWrapper>
      </Flexbox>
    </ColumnFlexbox>
  );
}
