import { BasicInfoContext } from "./basicInfoContext.jsx";
import { BarChart } from "../../components/barChart.jsx";
import { Flexbox } from "../../components/boxes.jsx";
import { FooterSection } from "../../components/footer.jsx";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { useFetch } from "../../hooks/useFetch.jsx";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Styled, { styled } from "styled-components";

const SearchBarBox = styled(Flexbox)`
  margin-bottom: 10px;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const SearchBarText = Styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

function SearchBar({ playerType, setComparisonPlayer }) {
  const tableInfo = useFetch(
    null,
    (data) => {
      const options = new Set();
      if (playerType == "goalie") {
        filteredData = data.filter((item) => item.position == "G");
      } else {
        filteredData = data.filter((item) => item.position != "G");
      }
      filteredData.forEach((item) => {
        const option = JSON.stringify({
          label: `${item.player}, ${item.team} (${item.position})`,
          value: item.player_id,
        });
        options.add(option);
      });
      return Array.from(options).map((option) => JSON.parse(option));
    },
    `player_meta_data_profile`,
    null,
    false
  );

  let capHitRanges = ["10-20%", "7.5-10%", "2.5-7.5%", "0-2.5%"];
  if (playerType == "forward") {
    capHitRanges = ["12-20%", "8-12%", "4-8%", "0-4%"];
  } else if (playerType == "defense") {
    capHitRanges = ["10-20%", "7.5-10%", "2.5-7.5%", "0-2.5%"];
  }

  const [selectedValue, setSelectedValue] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  return (
    <>
      {tableInfo["rowData"] && (
        <SearchBarBox>
          <SearchBarText>Select player to compare:</SearchBarText>
          <Select
            value={selectedValue}
            onChange={(e) => {
              setAutocompleteValue(null);
              setSelectedValue(e.target.value);
              setComparisonPlayer({ playerId: null, player: e.target.value });
            }}
            sx={{ width: 300, height: 25, fontSize: 14 }}
          >
            {capHitRanges.map((capHitRange, index) => (
              <MenuItem
                value={`Average ${String.fromCharCode(
                  65 + index
                )} tier ${playerType}`}
              >{`Average ${String.fromCharCode(
                65 + index
              )} tier ${playerType} (${capHitRange} cap hit)`}</MenuItem>
            ))}
          </Select>
          <SearchBarText>or enter player's name:</SearchBarText>
          <Autocomplete
            value={autocompleteValue}
            autoHighlight={true}
            options={tableInfo["rowData"]}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ "& .MuiInputBase-root": { height: 25, fontSize: 14 } }}
              />
            )}
            onChange={(e, value) => {
              setAutocompleteValue(value?.label);
              setSelectedValue(null);
              setComparisonPlayer({
                playerId: value?.value,
                player: value?.label.split(",")[0],
              });
            }}
          />
        </SearchBarBox>
      )}
    </>
  );
}

const ChartsBox = styled(Flexbox)`
  margin-bottom: 100px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

function getTOI(item) {
  const [minutes, seconds] = item.time_on_ice.split(":").map(Number);
  return minutes + seconds / 60;
}

function getValue(item, quantity, suffix, playerType) {
  let value = item[quantity];
  if (quantity == "time_on_ice") {
    value = getTOI(item);
  }
  if (quantity == "goals_against") {
    value = (item["goals_against_average"] * item["mins_played"]) / 60;
  }
  if (quantity.includes("percentage") & (quantity != "save_percentage")) {
    value = item[quantity.split("_percentage")[0]] / item["games_played"];
  }
  if (suffix == "_per_60_min") {
    if (playerType == "goalie") {
      value /= item["mins_played"] / 60;
    } else {
      value /= (getTOI(item) * item["games_played"]) / 60;
    }
  }
  if (suffix == "_per_game") {
    value /= item["games_played"];
  }
  return value;
}

function getYAxisTitle(quantity, suffix) {
  let yAxisTitle = quantity;
  if (["_per_60_min", "_per_game"].includes(suffix)) {
    yAxisTitle += suffix;
  }
  if (quantity == "time_on_ice") {
    yAxisTitle += " (min)";
  }
  return yAxisTitle.replace(/_/g, " ");
}

export function HeadToHeadTab() {
  const basicInfo = useContext(BasicInfoContext);
  let playerType = basicInfo["position_long"].toLowerCase();
  if (!["goalie", "defense"].includes(playerType)) {
    playerType = "forward";
  }
  const [comparisonPlayer, setComparisonPlayer] = useState({
    playerId: null,
    player: `Average ${basicInfo["salary_grade"]} tier ${playerType}`,
  });
  const { playerId } = useParams();

  function getRowData(data) {
    return data.filter((item) => item.player_id == playerId);
  }

  const tableInfo = useFetch(
    null,
    getRowData,
    `${playerType == "goalie" ? "goalie" : "skater"}_stats_profile`,
    comparisonPlayer
  );

  const chartInfo = {
    ...tableInfo,
    comparisonPlayer: comparisonPlayer,
    player: basicInfo["player"],
    playerType: playerType,
    salaryGrade: basicInfo["salary_grade"],
  };

  return (
    <>
      <SearchBar
        playerType={playerType}
        setComparisonPlayer={setComparisonPlayer}
      />
      {tableInfo.rowData && (
        <>
          <ChartsBox>
            {playerType == "goalie" ? (
              <>
                <BarChart
                  chartInfo={chartInfo}
                  getValue={getValue}
                  getYAxisTitle={getYAxisTitle}
                  quantity={"save_percentage"}
                  quantityOptions={[
                    { value: "save_percentage", label: "save (%)" },
                    { value: "wins_percentage", label: "wins (%)" },
                    { value: "losses_percentage", label: "losses (%)" },
                  ]}
                />
                <BarChart
                  chartInfo={chartInfo}
                  getValue={getValue}
                  getYAxisTitle={getYAxisTitle}
                  quantity={"goals_against"}
                  suffix={"_per_60_min"}
                  quantityOptions={[
                    { value: "goals_against", label: "goals against average" },
                    { value: "saves", label: "saves" },
                  ]}
                  suffixOptions={[
                    { value: "_per_60_min", label: "per 60 min" },
                    { value: "_per_game", label: "per game" },
                    { value: "total", label: "total" },
                  ]}
                />
                <BarChart
                  chartInfo={chartInfo}
                  getValue={getValue}
                  getYAxisTitle={getYAxisTitle}
                  quantity={"games_played"}
                  quantityOptions={[
                    { value: "games_played", label: "games played" },
                    { value: "games_started", label: "games started" },
                    { value: "mins_played", label: "mins played" },
                  ]}
                />
              </>
            ) : (
              <>
                <BarChart
                  chartInfo={chartInfo}
                  getValue={getValue}
                  getYAxisTitle={getYAxisTitle}
                  quantity={"goals"}
                  suffix={"_per_game"}
                  quantityOptions={[
                    { value: "goals", label: "goals" },
                    { value: "assists", label: "assists" },
                    { value: "points", label: "points" },
                    { value: "shots", label: "shots" },
                  ]}
                  suffixOptions={[
                    { value: "_per_game", label: "per game" },
                    { value: "_per_60_min", label: "per 60 min" },
                    { value: null, label: "total" },
                  ]}
                />
                <BarChart
                  chartInfo={chartInfo}
                  getValue={getValue}
                  getYAxisTitle={getYAxisTitle}
                  quantity={"time_on_ice"}
                />
              </>
            )}
          </ChartsBox>
          <FooterSection />
        </>
      )}
    </>
  );
}
