import Styled, { styled } from "styled-components";

export const MinContentBox = Styled.div`
  width: min-content;
`;

export const StartFlexbox = Styled.div`
    align-items: center;
    display: flex;
    width: 100%;
`;

export const Flexbox = styled(StartFlexbox)`
  justify-content: center;
`;

export const ColumnFlexbox = styled(Flexbox)`
  flex-direction: column;
`;

export const SearchBarBox = styled(Flexbox)`
  margin-bottom: 10px;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const SelectorBox = styled(Flexbox)`
  height: 25px;
  justify-content: start;
  margin-bottom: 30px;
  margin-left: 60px;
`;

export const ChartBox = Styled.div`
  height: 350px;
  margin: 20px;
  width: 33%;
  @media (max-width: 800px) {
    padding-bottom: 30px;
    width: 90%;
  }
`;

export const ChartsBox = styled(Flexbox)`
  margin-bottom: 100px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
