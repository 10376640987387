import { ChartBox, SelectorBox } from "../boxes.jsx";
import React, { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Selector } from "./selector.jsx";

export function Chart({
  chartData,
  comparisonChartData,
  chartType,
  getValue,
  getYaxisTitle,
  xQuantity,
  yQuantity,
  ySuffix = null,
  yQuantityOptions = null,
  ySuffixOptions = null,
  xAxisTitle = null,
}) {

  const [selectedOptions, setSelectedOptions] = useState({
    yQuantity: yQuantity,
    ySuffix: ySuffix,
  });

  const xValues = chartData.rowData.map((item) => item[xQuantity]);
  const data = {
    labels: xValues,
    datasets: [
      {
        label: chartData.rowData.map((item) => item.player)[0],
        data: chartData.rowData.map((item) =>
          getValue(
            item,
            selectedOptions.yQuantity,
            selectedOptions.ySuffix,
          )
        ),
        borderColor: "rgba(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
    ],
  };

  if (comparisonChartData.rowData) {
    const comparisonXvalues = comparisonChartData.rowData.map(
      (item) => item[xQuantity]
    );
    let comparisonYvalues = comparisonChartData.rowData.map((item) =>
      getValue(
        item,
        selectedOptions.yQuantity,
        selectedOptions.ySuffix,
      )
    );
    const valueMap = new Map(
      comparisonXvalues.map((comparisonXvalue, index) => [
        comparisonXvalue,
        comparisonYvalues[index],
      ])
    );
    comparisonYvalues = xValues.map((xValue) =>
      valueMap.has(xValue) ? valueMap.get(xValue) : null
    );
    data["datasets"].push({
      label: comparisonChartData.rowData.map((item) => item.player)[0],
      data: comparisonYvalues,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    });
  }

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: xAxisTitle != null,
          text: xAxisTitle,
        },
      },
      y: {
        title: {
          display: true,
          text: getYaxisTitle(selectedOptions.yQuantity, selectedOptions.ySuffix),
        },
      },
    },
  };

  return (
    <ChartBox>
      <SelectorBox>
        {yQuantityOptions && (
          <Selector
            options={yQuantityOptions}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            toChange={"yQuantity"}
          />
        )}
        {ySuffixOptions && (
          <Selector
            options={ySuffixOptions}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            toChange={"ySuffix"}
          />
        )}
      </SelectorBox>
      { (chartType == "bar") && (
        <Bar data={data} options={options} />
      )}
      { (chartType == "line") && (
        <Line data={data} options={options} />
      )}
    </ChartBox>
  );
}