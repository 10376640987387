import { ColumnFlexbox, Flexbox } from "./boxes.jsx";
import { StyledLink } from "./misc.jsx";
import React from "react";
import Styled from "styled-components";

const Footer = Styled.footer`
  padding-bottom: 25px;
  padding-top: 75px;
`;

const Logo = Styled.img`
  height: 25px;
  padding-left: 5px;
`;

const Nav = Styled.nav`
  padding-top: 10px; 
`;

export function FooterSection({ visible = true, navVisible = true }) {
  return (
    visible && (
      <Footer>
        <ColumnFlexbox>
          <Flexbox>
            <span>By</span>
            <Logo src="/images/logo_Wyatt_AI_wide_grey.svg"></Logo>
          </Flexbox>
          {navVisible && (
            <Nav>
              <StyledLink to="/">About</StyledLink>
            </Nav>
          )}
        </ColumnFlexbox>
      </Footer>
    )
  );
}
