import Styled, { styled } from "styled-components";

export const MinContentBox = Styled.div`
  width: min-content;
`
export const StartFlexbox = Styled.div`
    align-items: center;
    display: flex;
    width: 100%;
`;

export const Flexbox = styled(StartFlexbox)`
  justify-content: center;
`;

export const ColumnFlexbox = styled(Flexbox)`
  flex-direction: column;
`;