import { Chart } from "./chart.jsx";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import React from "react";

export function LineChart({
  chartData,
  comparisonChartData,
  getValue,
  getYaxisTitle,
  xQuantity,
  yQuantity,
  ySuffix = null,
  yQuantityOptions = null,
  ySuffixOptions = null,
  xAxisTitle,
}) {
  ChartJS.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale
  );

  return (
    <Chart
      chartData={chartData}
      comparisonChartData={comparisonChartData}
      chartType={"line"}
      getValue={getValue}
      getYaxisTitle={getYaxisTitle}
      xQuantity={xQuantity}
      yQuantity={yQuantity}
      ySuffix={ySuffix}
      yQuantityOptions={yQuantityOptions}
      ySuffixOptions={ySuffixOptions}
      xAxisTitle={xAxisTitle}
    />
  );
}
