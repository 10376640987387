import { BasicInfoContext } from "./basicInfoContext.jsx";
import {
  Flexbox,
  MinContentBox,
  StartFlexbox,
} from "../../components/boxes.jsx";
import { NoWrapP, Title, TeamLogo } from "../../components/misc.jsx";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Styled, { styled } from "styled-components";

const BasicInfoParentBox = styled(MinContentBox)`
  margin-bottom: 30px;
  margin-top: 60px;
`;

const BasicInfoBox = styled(MinContentBox)`
  border: 2px solid ${(props) => props.theme.colors.title};
  border-radius: 10px;
  padding: 7.5px;
`;

const Name = styled(Title)`
  font-family: "FranklinGothic";
  font-size: 2rem;
  margin-bottom: 5px;
`;

const TeamLogoBox = Styled.div`
    height: 80px;
    width: 80px;
    margin-right: 10px;
`;

const BasicInfoText = styled(NoWrapP)`
  line-height: 1.25;
`;

const Flag = Styled.img`
    height: 15px;
    margin-left: 5px;
`;

const Bold = Styled.span`
  font-family: 'FranklinGothic';
  color: ${(props) => props.theme.colors.title};
`;

export function BasicInfoSection({ setBasicInfo }) {
  const basicInfo = useContext(BasicInfoContext);
  const { playerId } = useParams();

  useEffect(() => {
    fetch(`/data/players/${playerId}/meta_data.json`)
      .then((response) => response.json())
      .then((data) => {
        setBasicInfo(data[0]);
      });
  }, []);

  return (
    basicInfo && (
      <>
        <BasicInfoParentBox>
          <Flexbox>
            <Name>{basicInfo["player"]}</Name>
          </Flexbox>
          <BasicInfoBox>
            <StartFlexbox>
              {basicInfo["team"] != "none" && (
                <TeamLogoBox>
                  <TeamLogo
                    src={`/images/team_logos/${basicInfo["team"]}.svg`}
                  ></TeamLogo>
                </TeamLogoBox>
              )}
              <BasicInfoText>
                {`${basicInfo["position_long"]} (shoots ${basicInfo["shoots_catches"]})`}
                <br />
                {`${basicInfo["age"]} yrs, ${basicInfo["height"]}, ${basicInfo["weight"]} lbs`}
                <br />
                {`Born ${basicInfo["birth_date_formatted"]},`}
                <br />
                {`${basicInfo["birth_city"]}, ${basicInfo["birth_country"]}`}
                <Flag
                  src={`/images/flags/${basicInfo["birth_country"]}.svg`}
                ></Flag>
                <br />
                <Bold>Cap hit:</Bold>
                {` $${basicInfo["cap_hit"].toLocaleString()} (${
                  basicInfo["cap_percentage"]
                }%)`}
              </BasicInfoText>
            </StartFlexbox>
          </BasicInfoBox>
        </BasicInfoParentBox>
      </>
    )
  );
}
