import { useEffect, useState } from "react";

function sort(data) {
  return data.sort((a, b) => parseInt(a.season) - parseInt(b.season));
}

export function useFetch(
  getColumnDef,
  getRowData,
  tableName,
  comparisonPlayer = null,
  sortBySeason = true
) {
  const [tableInfo, setTableInfo] = useState({
    rowData: null,
    comparisonRowData: null,
    columnDefs: null,
  });

  function getComparisonRowData(data) {
    if (comparisonPlayer["playerId"]) {
      return data.filter((item) => item.player_id == comparisonPlayer["playerId"]);
    } else {
      return data.filter((item) => item.player == comparisonPlayer["player"]);
    }
  }

  useEffect(() => {
    fetch(`/data/${tableName}.json`)
      .then((response) => response.json())
      .then((data) => {
        const rowData = getRowData(data);
        const seasons = sortBySeason ? rowData.map((entry) => entry.season): [];
        setTableInfo({
          rowData: sortBySeason ? sort(rowData) : rowData,
          comparisonRowData: comparisonPlayer
            ? sort(
                getComparisonRowData(data).filter((entry) =>
                  seasons.includes(entry.season)
                )
              )
            : null,
          columnDefs: getColumnDef
            ? Object.keys(data[0]).map((key) => getColumnDef(key))
            : null,
        });
      });
  },[comparisonPlayer]);

  return tableInfo;
}
